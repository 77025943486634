import { html, nothing } from 'lit';
import { BaseElement } from '../base.js';
import { request } from '../../lib/request.js';
import alertSuccess from '../../html/alert-success.js';
import alertError from '../../html/alert-error.js';
import { mapDeliveryDetails } from '../../lib/map-delivery-details.js';
import './modal-delivery-details.js';
import './modal-language.js';

export class ConnectAccountStatus extends BaseElement {
  static properties = {
    item: { state: true },
    updating: { state: true }
  };

  constructor() {
    super();
    this.item = null;
  }

  render() {
    if (!this.item) {
      return nothing;
    }
    const { item } = this;
    const is_active = item.status === 'ACTIVE';
    return html`
      ${is_active ? alertSuccess(item.status) : alertError(item.status)}
      <div class="my-4">
        ID: <shared-copy-id id=${item.account}></shared-copy-id>
      </div>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-1 row-cols-xl-2 g-4">
        ${this.renderTokenCard()} ${this.renderDeliveriesCard()}
        ${this.renderFlushCachesCard()} ${this.renderLanguageCard()}
      </div>
      <ul class="mt-5">
        <li>
          Mail:
          <a class="text-decoration-none" href="mailto:${item.email}">
            ${item.email}
          </a>
        </li>
      </ul>
    `;
  }

  renderTokenCard() {
    const { token } = this.item;
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Token</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              ?disabled=${this.updating}
              @click=${this.onResetToken}
            >
              Reset
            </button>
          </div>
          <div class="card-body">
            ${token
              ? html`<shared-copy-id id=${token}></shared-copy-id>`
              : '•••••'}
          </div>
        </div>
      </div>
    `;
  }

  onResetToken() {
    this.emit('modal:confirm', {
      description: `Do you really want to reset the token for this account?`,
      confirm: async () => {
        await this.resetToken();
      }
    });
  }

  async resetToken() {
    this.updating = true;
    const new_item = await request(
      'PATCH',
      `/connect-accounts/${this.item.account}/reset-token`
    );
    Object.assign(this.item, new_item);
    this.updating = false;
  }

  renderDeliveriesCard() {
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="mb-0 me-auto">Deliveries</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              @click=${this.onDeliveries}
            >
              Update
            </button>
          </div>
          <div class="card-body">${mapDeliveryDetails(this.item.settings)}</div>
        </div>
      </div>
    `;
  }

  onDeliveries() {
    this.emit(
      'modal:open',
      html`
        <modal-connect-account-deliveries
          .account="${this.item}"
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            if (!e.detail.settings) {
              delete this.item.settings;
            }
            this.requestUpdate();
          }}
        ></modal-connect-account-deliveries>
      `
    );
  }

  renderFlushCachesCard() {
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack gap-3">
            <h5 class="my-1 me-auto">Flush Caches</h5>
          </div>
          <ul class="list-group list-group-flush py-1">
            <li class="list-group-item hstack">
              <div class="me-auto" alt="scope">Connect Products</div>
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                ?disabled=${this.updating}
                @click=${() => this.onFlushCaches()}
              >
                Flush
              </button>
            </li>
          </ul>
        </div>
      </div>
    `;
  }

  onFlushCaches() {
    this.emit('modal:confirm', {
      description: `Do you really want to flush the products cache for this account?`,
      confirm: async () => {
        this.updating = true;
        await request(
          'DELETE',
          `/connect-accounts/${this.item.account}/caches`
        );
        this.updating = false;
      }
    });
  }

  renderLanguageCard() {
    const lang = this.item.lang || 'de';
    return html`
      <div class="col">
        <div class="card">
          <div class="card-header hstack">
            <h5 class="mb-0 me-auto">Language</h5>
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              ?disabled=${this.updating}
              @click=${this.onChangeLanguage}
            >
              Change
            </button>
          </div>
          <div class="card-body">${lang}</div>
        </div>
      </div>
    `;
  }

  onChangeLanguage() {
    this.emit(
      'modal:open',
      html`
        <modal-connect-account-language
          .account=${this.item}
          @updated=${(e) => {
            Object.assign(this.item, e.detail);
            this.requestUpdate();
          }}
        ></modal-connect-account-language>
      `
    );
  }
}

customElements.define('connect-account-status', ConnectAccountStatus);
